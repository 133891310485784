<template>
    <div>
        <kendo-grid 
            ref="grid"
            :data-source="dataSource"
            :pageable="pageableConfig"
            :filterable="filterableConfig"
            :sortable="true"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <file-viewer ref="fileViewer"/>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import FileViewer from '../../../../shared/FileViewer.vue';
import commissionSpecService from '../Script/CommissionSpecService.js';

export default {
    name: 'CommissionSpecGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['editClick', 'statusClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"commission\".\"status\"" },
                                { field: "head_contact_name", new_field: "\"Contact\".\"head_contact_name\"" },
                                { field: "contact_person_name", new_field: "\"Contact\".\"contact_person_name\"" },
                                { field: "phone_number", new_field: "\"Contact\".\"phone_number\"" },
                                { field: "position", new_field: "\"Contact\".\"position\"" },
                                { field: "bank_account_number", new_field: "\"Contact\".\"bank_account_number\"" },
                                { field: "bank_account_name", new_field: "\"Contact\".\"bank_account_name\"" },
                                { field: "npwp", new_field: "\"Contact\".\"npwp\"" },
                                { field: "notes", new_field: "\"commission\".\"notes\"" },
                                { field: "created_by", new_field: "\"commission\".\"created_by\"" },
                                { field: "created_at", new_field: "\"commission\".\"created_at\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging,
                                type : 'commission_spec',
                                addable : false
                            }
                            return { 
                                query: commissionSpecService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetCommission.commission == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommission.commission;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetCommission.commission == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommission.total;
                        }
                    },
                    model: {
                        fields: {
                            commission_value: {type: "number" },
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "commission_number", title: "No. Komisi Spek", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "commission_value", title: "Nilai Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"}, format: "{0:N0}" },
                { field: "attachment", title: "Attachment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile 
                },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                
                { field: "head_contact_name", title: "Nama Afiliasi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "contact_person_name", title: "Nama Kontak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "phone_number", title: "No. Telpon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "position", title: "Jabatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "bank_account_number", title: "No. Akun Bank", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "bank_account_name", title: "Nama Akun Bank", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "npwp", title: "NPWP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var vueComponent = this;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;

        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.commission_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.commission_id, true);
        })

        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            statusClick(dataItem.commission_id, dataItem.status);
        })
        
        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })
    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        columnButton(){
            return this.$globalfunc.gridActionStatusButtonWithoutDelete("Komisi Spek")
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"commission\".\"status\"" },
                                { field: "head_contact_name", new_field: "\"Contact\".\"head_contact_name\"" },
                                { field: "contact_person_name", new_field: "\"Contact\".\"contact_person_name\"" },
                                { field: "phone_number", new_field: "\"Contact\".\"phone_number\"" },
                                { field: "position", new_field: "\"Contact\".\"position\"" },
                                { field: "bank_account_number", new_field: "\"Contact\".\"bank_account_number\"" },
                                { field: "bank_account_name", new_field: "\"Contact\".\"bank_account_name\"" },
                                { field: "npwp", new_field: "\"Contact\".\"npwp\"" },
                                { field: "notes", new_field: "\"commission\".\"notes\"" },
                                { field: "created_by", new_field: "\"commission\".\"created_by\"" },
                                { field: "created_at", new_field: "\"commission\".\"created_at\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging,
                                type : 'commission_spec',
                                addable : false,
                                status : status
                            }
                            return { 
                                query: commissionSpecService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetCommission.commission == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommission.commission;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetCommission.commission == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommission.total;
                        }
                    },
                    model: {
                        fields: {
                            commission_value: {type: "number" },
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            });
        }
    }
}
</script>

<style scoped>
</style>