<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Komisi Spek : {{CommissionSpecNo}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Komisi Spek</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="CommissionSpecNo" v-model="CommissionSpecNo" class="font-weight-bold" readonly/>
                            <label id="errorCommissionSpecNo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Afiliasi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Afiliation" v-model="Afiliation" class="font-weight-bold" readonly/>
                            <label id="errorAfiliation" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Kontak</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ContactName" v-model="ContactName" class="font-weight-bold" readonly/>
                            <label id="errorContactName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Telpon</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PhoneNumber" v-model="PhoneNumber" class="font-weight-bold" readonly/>
                            <label id="errorPhoneNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Jabatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Position" v-model="Position" class="font-weight-bold" readonly/>
                            <label id="errorPosition" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">NPWP</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Npwp" v-model="Npwp" class="font-weight-bold" type="number" readonly/>
                            <label id="errorNpwp" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Akun Bank</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="BankAccNo" v-model="BankAccNo" class="font-weight-bold" readonly/>
                            <label id="errorBankAccNo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Akun Bank</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="BankAccName" v-model="BankAccName" class="font-weight-bold" readonly/>
                            <label id="errorBankAccName" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nilai Komisi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="CommissionValue" v-model="CommissionValue" class="font-weight-bold" type="number" min="0" />
                            <label id="errorCommissionValue" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" />
                            <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="Note" class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                             <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div>
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </CRow>

                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/commission-spec')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import { role_flag_name } from '../../../../infrastructure/constant/variable.js';

import FileViewer from '../../../../shared/FileViewer.vue';
import commissionSpecServices from '../Script/CommissionSpecService.js';

export default {
    name: 'CommissionSpecForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'file-viewer': FileViewer,
    },
    props: ['reload', 'addClick'],
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            // //Detail
            Id: this.$route.params.id,
            ContactData: this.$route.params.data,
            Key: this.$route.params.data,

            CommissionSpecId: '',
            CommissionSpecNo: '',

            Afiliation: '',
            ContactName: '',
            PhoneNumber: '',
            Position: '',
            BankAccNo: '',
            BankAccName: '',
            Npwp: '',

            CommissionValue: 0,
            StatusData: [],
            Status: '',
            Note: '',
            
            File: '',
            FileStatus: '',
        }   
    },
    async mounted () {
        this.$loading(true);

        //Create Form
        if(this.FormType == 'Add'){
            this.CommissionSpecId = null;
            this.CommissionSpecNo = 'Auto';

            var data = JSON.parse(sessionStorage.getItem(this.Key));

            this.Afiliation = data.head_contact_name;
            this.ContactName = data.contact_person_name;
            this.PhoneNumber = data.phone_number;
            this.Position = data.position;
            this.BankAccNo = data.bank_account_number;
            this.BankAccName = data.bank_account_name;
            this.Npwp = data.npwp;

            this.CommissionValue = 0;
            this.StatusData = [
                { value:'New', label:'New' }
            ];
            this.Status = this.StatusData.find(c => c.value == 'New');
            this.Note = '';
            
            //File
            this.File = '';

            this.SaveType = 'Add';
        } 
        else {
            var data = await commissionSpecServices.GetCommissionSpecDetail(this.Id)

            this.CommissionSpecId = data.commission_id;
            this.CommissionSpecNo = data.commission_number;

            this.Afiliation = data.head_contact_name;
            this.ContactName = data.contact_person_name;
            this.PhoneNumber = data.phone_number;
            this.Position = data.position;
            this.BankAccNo = data.bank_account_number;
            this.BankAccName = data.bank_account_name;
            this.Npwp = data.npwp;

            this.CommissionValue = data.commission_value;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_commission_spec');
            this.Status = this.StatusData.find(c => c.value == data.status);
            this.Note = data.notes;
            
            //File
            this.File = data.attachment;

            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }

            if(this.Status.value != 'New') {
                document.getElementById('CommissionValue').disabled = true;
                document.getElementById('Note').disabled = true;
                document.getElementById('deleteAttachmentButton').disabled = true;
                document.getElementById('File').disabled = true;
            }
        }

        this.$loading(false);
    },
    methods: {
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (parseFloat(this.CommissionValue) < 1) {
                this.errorShow('errorCommissionValue');
            }
        },
        saveClick(){
            this.inputValidation();

            if (this.Error == 0) {
                //file
                var file = null
                if(this.File == '' || this.File == null){
                    file = null
                } else {
                    file = document.getElementById('File').files[0];
                };

                const commissionContact = {
                    head_contact_name : this.Afiliation,
                    contact_person_name : this.ContactName,
                    phone_number : this.PhoneNumber,
                    position : this.Position,
                    bank_account_number : this.BankAccNo,
                    bank_account_name : this.BankAccName,
                    npwp : this.Npwp,
                }

                const commissionSpec = {
                    commission_id : parseInt(this.CommissionSpecId),
                    commission_value : parseFloat(this.CommissionValue),
                    status : this.Status.value,
                    commission_detail_estimation_id : null,
                    new_commission_contact : commissionContact,
                    commission_type : 'commission_spec',
                    attachment : file,
                    notes : this.Note,
                }
                
                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : commissionSpec
                    }

                    commissionSpecServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/commission-spec' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.CommissionSpecId),
                        data : commissionSpec
                    }
                    
                    commissionSpecServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/commission-spec'
                                }
                            });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    }
}

</script>


<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>