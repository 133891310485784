import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class CommissionSpecService {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $type:String, $addable:Boolean!, $status:String) {
                GetCommission (Paging:$paging, CommissionType:$type, CommissionSpecAddable:$addable, Status:$status) {
                    commission {
                        created_at
                        last_update
                        commission_id
                        commission_number
                        commission_value
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                        status
                        commission_detail_estimation_id
                        max_commission
                        given_commission
                        attachment
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async GetCommissionSpecDetail(id) {
        const variables = {
            id : id,
            type : 'commission_spec',
            addable : false
        }
        var query = gql`
            query ($id:Int, $type:String, $addable:Boolean!) {
                GetCommission (CommissionID:$id, CommissionType:$type, CommissionSpecAddable:$addable) {
                    commission {
                        created_at
                        last_update
                        commission_id
                        commission_number
                        commission_value
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                        status
                        commission_detail_estimation_id
                        max_commission
                        given_commission
                        attachment
                        notes
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetCommission.commission[0];
    }
    
    GetContactDataQuery(){
        var query = `
            query {
                GetCommissionContactableDetailList {
                    commission_contact {
                        created_at
                        last_update
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                    }
                    total
                }
            }
        `;
        return query;
    }
        
    async addQuery(variables){
        var query = gql`
            mutation($data:NewCommission!){
                CreateCommission(commission:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
        mutation($id:Int!, $data:NewCommission!){
            UpdateCommission(CommissionID:$id,  commission:$data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new CommissionSpecService();